import { FC, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Trans, useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { useSelector } from "react-redux";
import { ApiError } from "@/interfaces/api/error";
import { selectIsTrial } from "@/store/subscription";
import { useGetUpcomingInvoiceQuery } from "@/repositories/invoice";
import { useCancelSubscriptionMutation, useRedeemCancellationOfferMutation } from "@/repositories/subscription";
import { ISubscriptionCancellationForm, subscriptionCancellationFormSchema } from "@/interfaces/subscription_cancellation/subscription_cancellation_form";

// Styes
import { ArrowRight } from "@carbon/icons-react";

// Components
import RescueModal from "./rescue_modal";
import Modal from "@/components/modal/modal";
import CancelSubscriptionForm from "./cancel_subscription_form";


interface _CancelSubscriptionModalProps {
    open: boolean;
    onClose: () => void;
}

const CancelSubscriptionModal: FC<_CancelSubscriptionModalProps> = ({
    open,
    onClose
}) => {

    const { t } = useTranslation();
    const isTrial = useSelector(selectIsTrial);
    const [openModal, setOpenModal] = useState<string|null>(null);
    const { data: invoice } = useGetUpcomingInvoiceQuery();
    const [redeemOffer, { isLoading: isRedeeming }] = useRedeemCancellationOfferMutation();
    const [cancelSubscription, { isLoading: isCancelling }] = useCancelSubscriptionMutation();
    const nextInvoiceDate = invoice ? new Date(invoice.date).toLocaleDateString() : '-';

    const formMethods = useForm<ISubscriptionCancellationForm>({
        resolver: zodResolver(subscriptionCancellationFormSchema),
        mode: 'onBlur',
        defaultValues: {
            reason: '',
            comments: ''
        }
    });

    // NOTE - Only used during campaigns
    // const handleConfirmCancel = (data: ISubscriptionCancellationForm) => {
    //     if (isTrial) return submit(data);
    //     setOpenModal('rescue');
    // }

    const redeem = () => {
        setOpenModal(null);
        const params = {discount: 50}
        redeemOffer(params).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.billing.rescueRedeemed.title'),
                message: t('notifications.billing.rescueRedeemed.message')
            })
            formMethods.reset();
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const submit = (data: ISubscriptionCancellationForm) => {
        // NOTE - Guard to prevent use unless enabled
        // return;
        setOpenModal(null);
        cancelSubscription(data).unwrap().then(() => {
            showToast({type: 'success', title: t(`notifications.billing.${isTrial ? 'trialCancelled' : 'subscriptionCancelled'}`)})
            formMethods.reset();
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                title={t(`modals.${isTrial ? 'cancelTrial' : 'cancelSubscription'}.title`)}
                text={<Trans
                    i18nKey={`modals.${isTrial ? 'cancelTrial' : 'cancelSubscription'}.text`}
                    components={{ strong: <strong /> }}
                    values={{ date: nextInvoiceDate }}
                />}
                children={<FormProvider {...formMethods}>
                    <CancelSubscriptionForm />
                </FormProvider>}
                action1={{
                    kind: "danger-ghost",
                    label: t(`components.buttons.${isTrial ? 'confirmCancelTrial' : 'confirmCancelSubscription'}`),
                    icon: <ArrowRight />,
                    onClick: formMethods.handleSubmit(submit),
                    loading: isCancelling || isRedeeming,
                }}
                cancel={{
                    label: t(`components.buttons.${isTrial ? 'keepTrial' : 'keepSubscription'}`),
                    onClick: onClose,
                    disabled: isCancelling || isRedeeming
                }}
                sx={{'& .MuiPaper-root': {maxWidth: '540px', background: 'var(--background)'}}}
                />
                {openModal === 'rescue' && <RescueModal 
                    open={openModal === 'rescue'} 
                    onClose={() => setOpenModal(null)}
                    onCancel={formMethods.handleSubmit(submit)}
                    onReedem={redeem}
                    />}
        </>
    )
}

export default CancelSubscriptionModal;
